<template #content>
  <div>
    <div v-if="loading == true">
      <div class="spinner"></div>
    </div>
    <div v-if="showForm == false">
      <div v-if="this.server_success" id="first_time_success" class="alert alert-success alert-register">{{(this.translation["3028"])}}</div>
    </div>
    <div v-if="showForm == true">
      <div v-if="this.errors.length" class="alert alert-info alert-register">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors">{{ error }}</li>
        </ul>
      </div>
      <div v-if="this.server_error" id="first_time_error" class="alert alert-danger alert-register"><span>{{this.errorMessage}}</span><span style="display:block;">Please contact the FreightStation Helpdesk</span></div>
      <form>
        <!-- Step 1 -->
        <Transition name="step">
          <div v-if="step === 1">
            <div>
              <div class="form-group row">
                <div class="col-lg-4">
                  <label>
                    {{(this.translation["3001"])}}*
                  </label>
                </div>
                <div class="col-lg-8">
                  <input v-model="companyName" name="companyName" class="form-control" required />
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <!-- end Step 1 -->
        <!-- Step 2 -->
        <Transition name="step">
          <div v-if="step === 2">
            <div class="form-group row">
              <div class="col-lg-2">
                <label>
                  {{(this.translation["3002"])}}*
                </label>
              </div>
              <div class="col-lg-10">
                <input v-model="companyAddress" name="companyAddress" class="form-control" required />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3003"])}}*</label></div>
              <div class="col-lg-4">
                <input v-model="companyZipcode" name="companyZipcode" class="form-control" required />
              </div>
              <div class="col-lg-2"><label>{{(this.translation["3133"])}}*</label></div>
              <div class="col-lg-4">
                <input v-model="companyCity" name="companyCity" class="form-control" required />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3005"])}}*</label></div>
              <div class="col-lg-4">
                <select name="companyCountry" class="form-control" v-model="companyCountry" required>
                  <option v-for="(item, key, index) in isoCountries" :key="index" :value="key">
                    {{item}}
                  </option>
                </select>
              </div>
              <div class="col-lg-2" v-if="companyCountry == 'US'"><label>{{(this.translation["3004"])}}*</label></div>
              <div class="col-lg-4" v-if="companyCountry == 'US'">
                <!--<input v-model="companyState" name="companyState" class="form-control" />-->
                <select v-model="companyState" name="companyState" class="form-control" required>
                  <option value="" selected="selected">Select a State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3007"])}}</label></div>
              <div class="col-lg-4"><input v-model="companyWebsite" name="companyWebsite" class="form-control" /></div>
              <div class="col-lg-2"><label>{{(this.translation["3006"])}}</label></div>
              <div class="col-lg-4"><input v-model="companyPhone" type="tel" class="form-control"  id="companyPhone" ref="companyPhone" name='companyPhone' maxlength="20"></div>
            </div>
          </div>
        </Transition>
        <!-- end Step 2 -->

        <!-- Step 3 -->
        <Transition name="step">
          <div v-if="step === 3">
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3008"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyGovRegNumber" name="companyGovRegNumber" class="form-control" required /></div>
              <div class="col-lg-2"><label>{{(this.translation["3009"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyVat" name="companyVat" class="form-control" required /></div>
            </div>
            <div class="form-group row" v-if="companyCountry != 'US'">
              <div class="col-lg-2"><label>{{(this.translation["3010"])}}</label></div>
              <div class="col-lg-10"><input v-model="companyRaNumber" name="companyRaNumber" class="form-control" /></div>
            </div>
          </div>
        </Transition>
        <!-- end Step 3 -->

        <!-- Step 4 -->
        <Transition name="step">
          <div v-if="step === 4">
            <h5 class="modal-title" id="exampleModalLabel">{{(this.translation["3011"])}}</h5>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3012"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyAdminName" name="companyAdminName" class="form-control" required/></div>
              <div class="col-lg-2"><label>{{(this.translation["3013"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyAdminSurname" name="companyAdminSurname" class="form-control" required/></div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3014"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyJobTitle" name="companyJobTitle" class="form-control" required/></div>
              <div class="col-lg-2"><label>{{(this.translation["3015"])}}</label></div>
              <div class="col-lg-4">
                <select name="companyGender" class="form-control" v-model="companyGender">
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="X">Other</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3016"])}}*</label></div>
              <div class="col-lg-10"><input type="email" v-model="companyEmail" name="companyEmail" class="form-control" required/></div>
            </div>
            <div class="form-group row">
              <!--<div class="col-lg-2"><label>{{(this.translation["3017"])}}</label></div>
              <div class="col-lg-4"><input v-model="companyPhone" type="tel" class="form-control"  id="companyPhoneNumber" ref="companyPhoneNumber" name='companyPhoneNumber' maxlength="20"></div>-->
              <div class="col-lg-6"><label>{{(this.translation["3018"])}}</label></div>
              <div class="col-lg-6"><input v-model="companyMobileNumber" type="tel" class="form-control"  id="companyMobileNumber" ref="companyMobileNumber" name='companyMobileNumber' maxlength="20"></div>
            </div>
          </div>
        </Transition>
        <!-- end Step 4 -->

        <!-- step 5 -->
        <Transition name="step">
          <div v-if="step === 5">
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3020"])}}</label></div>
              <div class="col-lg-10">
                <select name="companyAirport" class="form-control" v-model="companyAirport">
                  <option value="2040">Amsterdam (AMS)</option>
                  <option value="2518">Barcelona (BCN)</option>
                  <option value="2484">Brussels (BCN)</option>
                  <option value="2494">Cape Town (CPT)</option>
                  <option value="2509">Charles de Gaulle (CDG)</option>
                  <option value="2515">Copenhagen (CPH)</option>
                  <option value="2526">Dublin (DUB)</option>
                  <option value="2494">Johannesburg (JNB)</option>
                  <option value="2500">Liege (LGG)</option>
                  <option value="2490">London Heathrow (LHR)</option>
                  <option value="2517">Madrid (MAD)</option>
                  <option value="2490">Manchester (MAN)</option>
                  <option value="8204">Miami (MIA)</option>
                  <option value="2516">Milan (MXP)</option>
                  <option value="2519">Chicago (ORD)</option>
                  <option value="2526">Cork (ORK)</option>
                  <option value="2526">Shannon (SNN)</option>
                  <option value="2511">Vienna (VIE)</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3019"])}}</label></div>
              <div class="col-lg-4">
                <select name="companyLanguage" class="form-control" v-model="companyLanguage">
                  <option value="en">EN</option>
                  <option value="es">ES</option>
                  <option value="ca">CA</option>
                  <option value="fr">FR</option>
                  <option value="nl">NL</option>
                </select>
              </div>

              <div class="col-lg-2"><label>{{(this.translation["3021"])}}</label></div>
              <div class="col-lg-4">
                <select name="companyType" class="form-control" v-model="companyType">
                  <option value="agent">{{(this.translation["3025"])}}</option>
                  <option value="haulier">{{(this.translation["3026"])}}</option>
                  <option value="other">{{(this.translation["3027"])}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3022"])}}</label></div>
              <div class="col-lg-10"><input v-model="companyRemarks" name="companyRemarks" class="form-control" required/></div>
            </div>
          </div>
        </Transition>
        <!-- end step 5 -->
      </form>
      <div class="row">
        <div v-if="step !== 1" class="text-left col-sm-6"><button class="btn btn-info" type="button" @click="decrementStep">Previous Step</button> </div>
        <div v-if="step === 1" class="text-left col-sm-6"></div>
        <div v-if="step !== 5" class="text-right col-sm-6"><button class="btn btn-info" type="button" @click="incrementStep" >Next Step</button> </div>
        <div v-if="step === 5" class="text-right col-sm-6"><button class="btn btn-info" type="button" @click="submit" >Submit</button> </div>
      </div>
    </div>

  </div>
</template>

<style>

.step-enter {
  Opacity: 0;
}
.step-enter-active {
  transition : opacity 1s;
}
.step--leave {
  Opacity: 0;
}
.step-leave-active {
}

.alert-register {
  margin-top: -20px;
  margin-bottom: 20px;
}

</style>
<script>
import {isoCountries} from "./country_iso_codes.js"

export default {
  name: 'Register',
  props:['trans'],
  data() {
    return{
      step: 1,
      companyName: '',
      companyAddress: '',
      companyZipcode: '',
      companyState: '',
      companyCountry: '',
      companyPhone: '',
      companyWebsite: '',
      companyGovRegNumber: '',
      companyVat: '',
      companyRaNumber: '',
      companyAdminName: '',
      companyAdminSurname: '',
      companyJobTitle: '',
      companyGender: '',
      companyEmail: '',
      companyPhoneNumber: '',
      companyMobileNumber: '',
      companyAirport: '',
      companyLanguage: '',
      companyType: '',
      companyRemarks: '',
      companyCity: '',
      errors: [],
      server_success: false,
      server_error: false,
      errorMessage: "",
      translation: [],
      showForm: true,
      loading: false,
      isoCountries: isoCountries
    }
  },
  created(){
    this.setTranslation()
    this.showForm = true
    this.loading = false
    let _this = this
    $('#firstTimeModal').on('shown.bs.modal', function () {
      _this.cleanForm();
      _this.showForm = true
      _this.step = 1

    });

  },
  mounted() {
  },
  methods:{
    async submit() {
      this.loading = true
      this.showForm = false;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json",'X-Requested-With': 'XMLHttpRequest' },
        body: JSON.stringify({
          "_token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          form: {
            "companyName": this.companyName,
            "companyAddress": this.companyAddress,
            "companyZipcode": this.companyZipcode,
            "companyState": this.companyState,
            "companyCountry": this.companyCountry,
            "companyPhone": this.companyPhone,
            "companyWebsite": this.companyWebsite,
            "companyGovRegNumber": this.companyGovRegNumber,
            "companyVat": this.companyVat,
            "companyRaNumber": this.companyRaNumber,
            "companyAdminName": this.companyAdminName,
            "companyAdminSurname": this.companyAdminSurname,
            "companyJobTitle": this.companyJobTitle,
            "companyGender": this.companyGender,
            "companyEmail": this.companyEmail,
            "companyPhoneNumber": this.companyPhoneNumber,
            "companyMobileNumber": this.companyMobileNumber,
            "companyAirport": this.companyAirport,
            "companyLanguage": this.companyLanguage,
            "companyType": this.companyType,
            "companyRemarks": this.companyRemarks,
            "companyCity": this.companyCity,
          }
        })
      };
      const response = await fetch("/register_company", requestOptions)
      if( response.ok == false) {
        this.loading = false;
        this.server_error = true;
        this.errorMessage = response.statusText;
        this.server_success = false;
        this.showForm = true;
        return;
      };
      const result = await response.json();
      this.loading = false
      if( result.error == false) {
        this.server_error = false;
        this.errorMessage = "";
        this.server_success = true;
        this.cleanForm();
        this.showForm = false;
      } else {
        this.server_error = true;
        this.errorMessage = result.message;
        this.server_success = false;
        this.showForm = true;
      }
    },
    setTranslation(){
      this.translation = this.trans;
    },
    initializePhone(element) {
      $(element).intlTelInput( {
        // allowDropdown: false,
        autoHideDialCode: true,
        // autoPlaceholder: "off",
        // dropdownContainer: "body",
        // excludeCountries: ["us"],
        // formatOnDisplay: false,
        // hiddenInput: "full_number",
        initialCountry: "nl",
        nationalMode: true,
        onlyCountries: ["al","ad","at","by","be","ba","bg","hr","cz","dk","ee","fo","fi","fr","de","gi","gr","va","hu","is","ie","it","lv","li","lt","lu","mk","mt","md","mc","me","nl","no","pl","pt","ro","ru","sm","rs","sk","si","es","se","ch","ua","gb"],
        placeholderNumberType: "MOBILE",
        preferredCountries: ['es','de','nl','at','be','fr'],
        separateDialCode: false,
        utilsScript: ""
      });
      $(element).on('blur',function(){if($(this).intlTelInput("isValidNumber")) {$(this).closest('.form-group').removeClass('has-error'); $(this).val($(this).intlTelInput("getNumber")); } else {$(this).closest('.form-group').addClass('has-error'); $(this).val(''); } });
      $.fn.intlTelInput.loadUtils("/js/utils.js");
    },
    cleanForm() {
      this.companyName = "";
      this.companyAddress = "";
      this.companyZipcode = "";
      this.companyState = "";
      this.companyCountry = "";
      this.companyPhone = "";
      this.companyWebsite = "";
      this.companyGovRegNumber = "";
      this.companyVat = "";
      this.companyRaNumber = "";
      this.companyAdminName = "";
      this.companyAdminSurname = "";
      this.companyJobTitle = "";
      this.companyGender = "";
      this.companyEmail = "";
      this.companyPhoneNumber = "";
      this.companyMobileNumber = "";
      this.companyAirport = "";
      this.companyLanguage = "";
      this.companyType = "";
      this.companyRemarks = "";
      this.companyCity = "";
    },
    setErrors: function () {
      this.errors = [];

      if (!this.companyName && this.step == 1) {
        this.errors.push('Company name required');
      }
      if (!this.companyAddress && this.step == 2) {
        this.errors.push('Adress required.');
      }
      if (!this.companyZipcode && this.step == 2) {
        this.errors.push('Zipcode required.');
      }
      if (!this.companyCountry && this.step == 2) {
        this.errors.push('Country required.');
      }
      if (!this.companyState && this.companyCountry == "US" && this.step == 2) {
        this.errors.push('State required.');
      }
      if (!this.companyCity && this.step == 2) {
        this.errors.push('City required.');
      }
      if (!this.companyGovRegNumber && this.step == 3) {
        this.errors.push('Registration number required.');
      }
      if (!this.companyVat && this.step == 3) {
        this.errors.push('VAT number required.');
      }
      if (!this.companyAdminName && this.step == 4) {
        this.errors.push('Admin name required.');
      }
      if (!this.companyAdminSurname && this.step == 4) {
        this.errors.push('Admin surname required.');
      }
      if (!this.companyJobTitle && this.step == 4) {
        this.errors.push('Job title required.');
      }
      if (!this.validateEmail(this.companyEmail) && this.step == 4) {
        this.errors.push('Email required.');
      }
      if (!this.companyMobileNumber && this.step == 4) {
        this.errors.push('Mobile number required.');
      }
    },
    handleIncrement: function () {
      this.errors = [];
      let self = this
      switch (this.step) {
        case 1:
          if (this.companyName) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }

          setTimeout(function () { self.initializePhone('#companyPhone') }, 500)

          break;
        case 2:
          if(this.companyCountry == 'US' && !this.companyState) {
            var stateFilled = false;
          } else {
            var stateFilled = true;
          }
          if (this.companyAddress && this.companyZipcode && this.companyCity && this.companyCountry && stateFilled) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          break;
        case 3:
          if (this.companyGovRegNumber && this.companyVat) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          setTimeout(function () {
            self.initializePhone('#companyPhoneNumber')
            self.initializePhone('#companyMobileNumber')
          }, 500)
          break;
        case 4:
          if (this.companyAdminName && this.companyAdminSurname && this.companyJobTitle && this.validateEmail(this.companyEmail)) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          break;
        case 5:
          this.step = this.step + 1;
          break;
      }
    },
    incrementStep() {
      this.handleIncrement();
    },
    handleDecrement: function () {
      this.errors = [];
      this.step = this.step - 1;
    
    },
    decrementStep() {
      this.handleDecrement();
    },
    validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
    } else {
        return false;
    }
    }
  }
}
</script>

